#nav-toggle {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    cursor: pointer;
    outline: none;
    margin-right: 1rem;

    span {
        transform: translateX(12px);
        &::before {
            top: -9px;
            transform: rotate(0);
        }

        &::after {
            bottom: -9px;
            transform: rotate(0);
        }
    }

    span,
    span::before,
    span::after {
        cursor: pointer;
        height: 2px;
        width: 26px;
        background: $primary;
        position: absolute;
        display: block;
        content: '';
        transition: all 0.3s ease-in-out;
    }

    &:not(.collapsed) {
        span {
            background-color: transparent;
            &::before {
                transform: rotate(45deg);
                transform-origin: top left;
            }

            &::after {
                transform: rotate(-45deg);
                transform-origin: bottom left;
            }
        }
    }
}

.navbar-brand {
    line-height: 4rem;
}

.nav-link {
    position: relative;
    padding: 0;

    &.active {
        font-weight: bold;
        &::after {
            content: '';
            display: block;
            background-color: $primary;
            position: absolute;
            bottom: -.25rem;
            left: 50%;
            margin-left: -.5rem;
            width: 1rem;
            height: .25rem;
            border-radius: .125rem;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }
}
.share-icons {
    a {
        margin-left: .5rem;
        opacity: 0;
        background-color: rgba($black, .05);
        border-radius: 99px;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        text-decoration: none;
        
        &[class*="share-"] {
            opacity: 0;
            transform: scale(0);
            transition: transform 1s;
            display: inline-block;
            transform-origin: 50% 50%;
        }
        
        &.share-in {
            @for $i from 1 through 4 {	
                &:nth-of-type(#{$i}) {
                    animation: socialin 250ms #{$i * 111}ms forwards;
                }
                &:nth-of-type(1) {
                    animation: socialin 250ms 850ms forwards;
                }
                &:nth-of-type(2) {
                    animation: socialin 250ms 600ms forwards;
                }
                &:nth-of-type(3) {
                    animation: socialin 250ms 350ms forwards;
                }
                &:nth-of-type(4) {
                    animation: socialin 250ms 100ms forwards;
                }
            }
        }
    }
}

.theme-toggle {
    display: block;
    height: 1.75rem;
    width: 2.875rem;
    border-radius: 1rem;
    border: .125rem solid $mid;
    position: relative;
    
    &:focus {
        border: .125rem solid $primary;

        &::before {
            background-color: $primary;
        }
    }

    &::before {
        content: 'E';
        display: block;
        height: 1.25rem;
        width: 1.25rem;
        position: absolute;
        top: .125rem;
        left: .125rem;
        background-color: $mid;
        border-radius: 1.25rem;
        line-height: 1.25rem;
        text-align: center;
        font-size: 1rem;
        color: $white;
        transition: left .15s ease-in-out;
    }

    &[data-theme="dark"] {
        &::before {
            content: 'F';
            left: 1.25rem;
            color: $darker;
        }
    }
}


@keyframes socialin {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
	100% {
        opacity: 1;
        transform: scale(1);
	}
}
