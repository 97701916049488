::selection {
    color: $white;
    background: $dark;
}
.dark-mode {
    ::selection {
        color: $dark;
        background: $lighter;
    }
}

.range-label {
    width: 3.5rem;
    padding: 0.25rem 0;
    border-radius: $border-radius;
    text-align: center;
    background-color: lighten($lighter, 5%);
    font-weight: 600;
    line-height: 1.25;
    font-size: 80%;
}

.colour-adjust {
    background-color: transparent;
    margin: 0 !important;
    border: 0;
    cursor: pointer;
    color: $primary;
    outline: none;

    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.dark-mode {
    .range-label {
        background-color: $dark;
        color: $light;
    }
}

.colour-circle {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99px;
    border: 0.125rem solid $mid;
    transform: translateY(1px);
    margin-right: 0.5rem;
}

.scribble {
    position: relative;
    white-space: nowrap;
    &:after {
        --deco-height: 0.3125em;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: calc(var(--deco-height) * -1.125);
        height: var(--deco-height);
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNS4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMDAgNjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwMCA2NDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6I0U4NjU1OTtzdHJva2Utd2lkdGg6MTA7fQ0KCS5zdDF7Y2xpcC1wYXRoOnVybCgjU1ZHSURfNF8pO30NCgkuc3Qye2ZpbGw6bm9uZTtzdHJva2U6I0YyMDBGRjtzdHJva2Utd2lkdGg6MTA7fQ0KPC9zdHlsZT4NCjxnPg0KCTxkZWZzPg0KCQk8cmVjdCBpZD0iU1ZHSURfMV8iIHg9Ii0xMDAiIHk9IjAuOCIgd2lkdGg9IjEwMCIgaGVpZ2h0PSI2NCIvPg0KCTwvZGVmcz4NCgk8Y2xpcFBhdGggaWQ9IlNWR0lEXzJfIj4NCgkJPHVzZSB4bGluazpocmVmPSIjU1ZHSURfMV8iICBzdHlsZT0ib3ZlcmZsb3c6dmlzaWJsZTsiLz4NCgk8L2NsaXBQYXRoPg0KPC9nPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MCIgZD0iTS0yMS43LDMzLjZjMTYtOC41LDcwLjEtMzMsNTIuMS0xNkMxNC4zLDMyLjksMjEuNyw0Myw0MC4yLDQxczM5LjgtOCw3MS42LTIyLjkiLz4NCjwvZz4NCjxnPg0KCTxkZWZzPg0KCQk8cmVjdCBpZD0iU1ZHSURfM18iIHg9Ii0xMDQuMSIgeT0iLTg1IiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjY0Ii8+DQoJPC9kZWZzPg0KCTxjbGlwUGF0aCBpZD0iU1ZHSURfNF8iPg0KCQk8dXNlIHhsaW5rOmhyZWY9IiNTVkdJRF8zXyIgIHN0eWxlPSJvdmVyZmxvdzp2aXNpYmxlOyIvPg0KCTwvY2xpcFBhdGg+DQoJPGcgY2xhc3M9InN0MSI+DQoJCTxwYXRoIGNsYXNzPSJzdDIiIGQ9Ik0tMTI1LjgtNTEuNGMxNi04LjUsNzAuMS0zMyw1Mi4xLTE2Qy04OS44LTUyLjEtODIuNC00Mi02My45LTQ0czQyLjYtMTIuNCw3NC42LTIzLjQiLz4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4NCg==');

        background-size: auto 100%;
        background-repeat: round;
        background-position: 0em;
    }
}

// Link overwrites
a:not(.nav-link):not(.tool-card):not(.btn) {
    font-weight: 900;
}

// Button overwrites
.btn {
    &:hover {
        color: $white;
        box-shadow: 0 0 0 0.375rem rgba($primary, 0.25);
    }
}
