.dark-mode {
    background-color: $darker;
    color: $lighter;

    .navbar {
        background-color: rgba($darker, 0.9);
    }

    .navbar-nav {
        .nav-link {
            color: $light;

            &.active,
            &:hover {
                color: $white;
            }
        }
    }

    .text-muted {
        color: lighten($mid, 5%) !important;
    }

    hr {
        border-top-color: $dark;
    }

    .form-control {
        background-color: rgba($dark, 0.666);
        border-color: darken($darker, 3%);
        color: $lighter;
    }

    .custom-switch {
        .custom-control-input {
            &:checked ~ .custom-control-label {
                &::after {
                    background-color: $darker;
                }
            }
        }
    }

    .input-group-text {
        color: $white;
        background-color: darken($darker, 3%);
        border: 0;
    }

    .dropdown-menu {
        background-color: $dark;
        color: $lighter !important;

        .dropdown-item {
            color: $lighter;

            &:hover {
                background-color: $darker;
            }
        }
    }

    &.popover {
        border: 1px solid $black;
    }
    .popover-header {
        background-color: darken($dark, 3%);
        border: 0;

        &::before {
            border-color: transparent !important;
        }
    }
    .popover-body {
        background-color: $dark;
        color: $lighter !important;
    }

    > .arrow::before {
        border-bottom-color: darken($black, 3%);
    }

    > .arrow::after {
        border-bottom-color: darken($dark, 3%);
    }

    code {
        background-color: darken($darker, 3%);
        color: $danger;
        padding: 0.25rem 0.5rem;
        border-radius: $border-radius;
    }

    .shadow {
        box-shadow: 0 0.5rem 1rem rgba($black, 0.4) !important;
    }

    .share-icons {
        a {
            background-color: $dark;
        }
    }
}
