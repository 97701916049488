.accordion {
    .accordion-toggle {
        color: $mid;
        padding: 0 .5rem;
        border-radius: $border-radius $border-radius 0 0;

        &.active {
            color: $black;
            background-color: lighten($lighter, 5%);
        }
    }

    .collapse,
    .collapsing {
        padding: 0 .5rem;
        background-color: lighten($lighter, 5%);
        border-radius: 0 0 $border-radius $border-radius;
    }
}

.dark-mode {
    .accordion {
        .accordion-toggle {
            color: $mid;
            padding: 0 .5rem;
            border-radius: $border-radius $border-radius 0 0;
    
            &.active {
                color: $white;
                background-color: $dark;
            }
        }
    
        .collapse,
        .collapsing {
            background-color: $darker;
        }
    }
}