//@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
/* Next line: remove after update to boostrap 4.7.x */
/* autoprefixer: off */

@font-face {
    font-family: 'Mona Sans';
    src: url('./Assets/font/Mona-Sans.woff2') format('woff2 supports variations'),
        url('./Assets/font/Mona-Sans.woff2') format('woff2-variations');
    font-weight: 200 900;
    font-stretch: 75% 125%;
}

@import './Assets/scss/variables/bootstrap-vars';

@import '~bootstrap/scss/bootstrap';

@import './Assets/scss/mixins/mixins';

@import './Assets/scss/partials/typography';
@import './Assets/scss/partials/icons';
@import './Assets/scss/partials/forms';
@import './Assets/scss/partials/common';
@import './Assets/scss/partials/dark-mode';

@import './Assets/scss/components/Loading';
@import './Assets/scss/components/BlockQuote';
@import './Assets/scss/components/BrandLogos';
@import './Assets/scss/components/Contrast';
@import './Assets/scss/components/Accordion';
@import './Assets/scss/components/Header';
@import './Assets/scss/components/ToolCard';
@import './Assets/scss/components/InfoCard';
@import './Assets/scss/components/Ghost';

// Pages
@import './Assets/scss/Pages/Home';

// Layout
html {
    font-family: 'Mona Sans';
    scroll-behavior: smooth;
    max-width: 100vw;
    overflow-x: hidden;
}

.navbar {
    background-color: rgba($white, 0.8);
    backdrop-filter: blur(0.25rem);
    -webkit-backdrop-filter: blur(0.25rem);
}
.app {
    min-height: 100vh;
    scroll-behavior: smooth;

    .content {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    main {
        display: flex;
        flex-direction: column;
        flex: 1;
        scroll-behavior: smooth;
        min-height: calc(100vh - 76px);
        z-index: 2;
        position: relative;

        section {
            margin-top: 6rem;
            margin-bottom: 6rem;
        }
    }
}

.dropdown-toggle {
    border-radius: 0 0.33rem 0.33rem 0 !important;
}

code {
    background-color: lighten($lighter, 5%);
    border-radius: $border-radius;
    padding: 0.25rem 0.5rem;
}

.cursor-pointer {
    cursor: pointer;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid $dark;
    box-shadow: 0 0 0px 1000px $darker inset;
    -webkit-text-fill-color: $white;
    -webkit-box-shadow: 0 0 0px 1000px $darker inset;
    transition: background-color 5000s ease-in-out 0s;
}
