input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-color: $primary;
        border: .125rem solid $white;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 1rem;
        margin-top: calc(-.75rem + 2px);

    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: .25rem;
        cursor: pointer;
        background: $light;
        border-radius: 9px;
      }
      
      &:focus::-webkit-slider-runnable-track {
        background: $dark;
      }

    &:focus {
        outline: none;
    }
}

.dark-mode {
    input[type="range"] {
        &::-webkit-slider-thumb {
            border: .125rem solid $dark;
        }

        &::-webkit-slider-runnable-track {
            background: $dark;
        }
          
        &:focus::-webkit-slider-runnable-track {
            background: lighten($dark, 5%);
        }
    }
}